import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src045276070/src/variametrix-site-2021/src/templates/benefit.js";
export const query = graphql`
  {
    icons: file(name: { eq: "iconset--ownership" }) {
      publicURL
    }
    banner: file(name: { eq: "banner--total-data-ownership" }) {
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64 
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const BenefitBlurb = makeShortcode("BenefitBlurb");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <BenefitBlurb title="Data accessibility" icon={props.data.icons.publicURL} iconOffset={0} mdxType="BenefitBlurb">
  <p>
    Our core modules always keep data under your control, even if your data
    source goes kaput.
  </p>
    </BenefitBlurb>
    <BenefitBlurb title="Unified datasets" icon={props.data.icons.publicURL} iconOffset={1} mdxType="BenefitBlurb">
  <p>
    Because you control how data is collected with VariaMetrix, there is no
    limit to the range of custom data and detailed information that can be
    collected. Our managed services partner's implementation services help you
    integrate VariaMetrix in your web site or app.
  </p>
    </BenefitBlurb>
    <BenefitBlurb title="Fine-grained access" icon={props.data.icons.publicURL} iconOffset={2} mdxType="BenefitBlurb">
  <p>
    VariaMetrix reports data in exact, concrete terms and doesn’t fuzz or
    obfuscate the data like “free” tools. We give you the actual information.
  </p>
    </BenefitBlurb>
    <BenefitBlurb title="Anytime export" icon={props.data.icons.publicURL} iconOffset={3} mdxType="BenefitBlurb">
  <p>
    With VariaMetrix, data is always available for export in whatever format you
    need . You can request all data to be turned over to you instead of living
    on third-party servers.
  </p>
    </BenefitBlurb>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      